import React from 'react';
import { Box, IconButton } from '@mui/material';
import { Facebook, Instagram } from '@mui/icons-material';

const SocialMedia: React.FC = () => {
    return (
        <Box sx={{ textAlign: 'center', my: 4 }}>
            <IconButton href="https://www.facebook.com/Rightcrewservices" target="_blank" aria-label="Facebook">
                <Facebook />
            </IconButton>
            <IconButton href="https://www.instagram.com/rightcrewservices/" target="_blank" aria-label="Instagram">
                <Instagram />
            </IconButton>
        </Box>
    );
}

export default SocialMedia;
