import React from 'react';
import { ThemeProvider, createTheme, CssBaseline, Container, Box } from '@mui/material';
import Logo from './Logo';
import ContactUs from './ContactUs';
import SocialMedia from './SocialMedia';

const theme = createTheme({
    palette: {
        background: {
            default: '#ffffff', // Set the background color to white
        },
    },
    components: {
        MuiPaper: {
            styleOverrides: {
                root: {
                    backgroundColor: '#ffffff', // Set the background color to white for paper components
                },
            },
        },
    },
});

const App: React.FC = () => {
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Container maxWidth="md">
                <Box sx={{ textAlign: 'center', py: 4 }}>
                    <Logo />
                    <ContactUs />
                    <SocialMedia />
                </Box>
            </Container>
        </ThemeProvider>
    );
}

export default App;
