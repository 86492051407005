import React from 'react';
import { Box, Typography } from '@mui/material';
import companyLogo from './assets/company-logo.jpg'; // Update the path as needed

const Logo: React.FC = () => {
    return (
        <Box sx={{ textAlign: 'center', my: 4 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <img src={companyLogo} alt="Right Crew Services Logo" style={{ width: '50px', marginRight: '15px' }} />
                <Typography variant="h5" component="h1">
                    Right Crew Services
                </Typography>
            </Box>
            <Typography variant="body2" sx={{ mt: 2, color: '#5d1e1e' }}>
                We elevate your maintenance experience with <strong>Right Crew Services</strong>—where expertise meets excellence
            </Typography>
        </Box>
    );
}

export default Logo;
