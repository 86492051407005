import React, { useState } from 'react';
import { Box, Typography, TextField, Button, Grid, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import axios from 'axios';

const API_URL = process.env.REACT_APP_OUTSIDE_API;

const ContactUs: React.FC = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        subject: '',
        message: '',
    });
    const [openSuccess, setOpenSuccess] = useState(false);
    const [openFail, setOpenFail] = useState(false);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        try {
            await axios.post(`${API_URL}/contact-us`, formData);
            setOpenSuccess(true);
        } catch (error) {
            setOpenFail(true);
        }
    };

    const handleCloseSuccess = () => {
        setOpenSuccess(false);
    };

    const handleCloseFail = () => {
        setOpenFail(false);
    };

    return (
        <Box sx={{ p: 2, my: 2 }}>
            <Typography variant="h5" gutterBottom>
                Contact Us
            </Typography>
            <Typography variant="body2" gutterBottom>
                If you have any questions or need assistance, please feel free to contact us using the form below.
            </Typography>
            <Box component="form" sx={{ '& .MuiTextField-root': { mb: 1 }, mb: 2 }} onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            required
                            label="Name"
                            variant="outlined"
                            size="small"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            required
                            label="Email"
                            variant="outlined"
                            size="small"
                            type="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            required
                            label="Subject"
                            variant="outlined"
                            size="small"
                            name="subject"
                            value={formData.subject}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            required
                            label="Message"
                            variant="outlined"
                            size="small"
                            multiline
                            rows={4}
                            name="message"
                            value={formData.message}
                            onChange={handleChange}
                        />
                    </Grid>
                </Grid>
                <Button variant="contained" color="primary" type="submit">
                    Send Message
                </Button>
            </Box>

            {/* Success Dialog */}
            <Dialog
                open={openSuccess}
                onClose={handleCloseSuccess}
                aria-labelledby="success-dialog-title"
                aria-describedby="success-dialog-description"
            >
                <DialogTitle id="success-dialog-title">Success</DialogTitle>
                <DialogContent>
                    <DialogContentText id="success-dialog-description">
                        Your message has been sent successfully!
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseSuccess} color="primary" autoFocus>
                        OK
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Failure Dialog */}
            <Dialog
                open={openFail}
                onClose={handleCloseFail}
                aria-labelledby="fail-dialog-title"
                aria-describedby="fail-dialog-description"
            >
                <DialogTitle id="fail-dialog-title">Failed</DialogTitle>
                <DialogContent>
                    <DialogContentText id="fail-dialog-description">
                        There was an error sending your message. Please try again later.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseFail} color="primary" autoFocus>
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default ContactUs;
